<template>

  <v-container
    fluid
    tag="section"
  >

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>




<base-material-card
      icon="mdi-arrow-top-right-bold-box"
      title="Upload"
      class="px-5 py-3">
<v-form ref="form" enctype="multipart/form-data">
    <v-card-text>
      <v-container>
        <v-row>
      
          <v-col cols="12" sm="12">
            <v-file-input  color="green accent-4"   accept="image/png, .pdf, .xlsx, .xls, .doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              show-size filled
              label="Selecionar arquivo (PDF | PNG | XLSX | XLS | DOCX | DOC) - Máx 10 mb" 
              @change="selectFile" 
            >
            
                <template v-slot:selection="{ text }">
                  <v-chip
                    small
                    label
                    color="primary"
                  >
                    {{ text }}
                  </v-chip>
                </template>  
                
            </v-file-input>
           
          
          </v-col>

        

          <v-col cols="12" sm="12">
            <v-select label="Empresa *" v-model="documento.empresa"  :items="empresas"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled prepend-inner-icon="mdi-bookmark"></v-select>
           </v-col>

            <v-col cols="12">
             <v-text-field 
              label="Titulo *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="documento.titulo" x-large
              prepend-inner-icon="mdi-account-box"
            ></v-text-field>

            </v-col>

            <v-col cols="12">
              <v-textarea 
               label="Descricão"  style="margin-left:4px;"
               filled v-model="documento.descricao" x-large
               prepend-inner-icon="mdi-account-box"
             ></v-textarea>
 
             </v-col>

         
           

            



        </v-row>
                          * campos obrigatórios
                     

                          

      </v-container> 



      <v-spacer> </v-spacer>
      <br/>
                      <v-btn color="success" :loading="this.btn_load"  @click="enviar()" >Confirmar
                      <v-icon right dark>mdi-check</v-icon>
      </v-btn>
    </v-card-text>
  </v-form>  
</base-material-card>
  </v-container>    
</template>
<script>

import Documento from '../../services/documento'

  export default {
    name: 'Upload',
   
    mounted () {
    },


    

    data () {
      return {
        components: {
            DashboardCoreView: () => import('../dashboard/components/core/View'),
        },
        dialog:true,
        alert_msg:false,
        showload_img: false,
        currentFile: undefined,
          progress: 0,
        msg_dialog:'',
        btn_load: false,
        empresas:['Grupo Parque das Flores','Cemitério Parque das Flores', 'Memorial Parque Maceió', 'Previda - Previparq', 'Cemitério Parque do Agreste - AM', 'GPF Corretora de Seguros', 'A Flor do Café - Melro & Sampaio', 'Cemitério Previda - VERSAN', 'Cemitério Reserva da Paz'],
       
        documento:{
            titulo: '',
            descricao:'',
            empresa: '',
            file: '',
            file_name: '',
        },
        loading:false,
      }
    },

   
    methods: {

        clear () {
          this.$refs.form.reset();
        },
  

      ok_val(){
        this.$router.push('login');
      },
     
      selectFile(file) {
        this.progress = 0;
        this.currentFile = file;
        this.documento.file_name = file.name;
      },


      enviar(){
        if (!this.currentFile) {
          alert('Por favor, selecione um arquivo');
          return;
        }
        this.btn_load = true;

        if(this.$refs.form.validate()){
          if(this.currentFile.size< 9999999){
           
              let formData = new FormData();
              formData.append("file", this.currentFile);
              formData.set("titulo", this.documento.titulo);
              formData.set("descricao", this.documento.descricao);
              formData.set("file_name", this.documento.file_name.normalize());
              formData.set("empresa", this.documento.empresa);
              Documento.enviar(formData).then(response => {

                    if(response.data.codigo){
                        this.msg_dialog = response.data.mensagem;
                        this.alert_msg = true;
                        this.clear();
                    }
                  }).catch(e => {
                   if(e == 'Error: timeout of 8000ms exceeded'){
                      this.loading = false
                      this.alert_msg = true;
                      this.btn_load = false;
                      this.msg_dialog = "Por Favor, Tente novamente ou verifique sua conexão";

                  }
                }).finally(() => {
                    this.loading = false
                    this.btn_load = false;
                  })
          
          }else{
            this.alert_msg = true;
            this.btn_load = false;
            this.msg_dialog = "Tamanho máximo 10 MB";
          }       
        }else{
            this.alert_msg = true;
            this.btn_load = false;
            this.msg_dialog = "Preencha todos os campos";

        }
      
    }

  }


  }
</script>